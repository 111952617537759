@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.scrollbar-hide {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }

/* Prevent zooming on inputs */
input, textarea, select {
  font-size: 16px;
}

/* Prevent scrolling globally */
html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* Remove horizontal scrolling */
body {
  overflow-x: hidden;
}

/* Disable pull-to-refresh on mobile browsers
body {
  overscroll-behavior-y: none;
} */

/* Enhance touch interactions */
html {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

.recipe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}


/* Style for each card */
.recipe-card {
  flex: 0 0 90%; /* Ensure cards don't shrink or grow */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  background-color: white;
  transform: translateX(0px); /* Default position */
  transition: transform 0.3s ease;
}
